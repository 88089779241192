<template>
  <v-form
    ref="form"
    class="send-mobile-app-link-dialog-sms-tab"
    @submit.prevent="handleSubmit"
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="form.number"
          outlined
          validate-on-blur
          :label="$trans('send_mobile_app_link_dialog_sms_tab_input_label')"
          placeholder="+485000123456"
          :rules="[rules.required]"
          :disabled="isLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" depressed type="submit" :loading="isLoading">
          {{ $trans("send_mobile_app_link_dialog_sms_tab_submit_button") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required } from "@/lib/calendesk-js-library/forms/validators";
import api from "@/lib/calendesk-js-library/api/api";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "SendMobileAppLinkDialogSMSTab",
  mixins: [sharedActions],
  data() {
    return {
      form: {
        number: null,
      },
      rules: {
        required,
      },
      isLoading: false,
    };
  },
  created() {
    this.form.number = this.loggedUser.default_phone
      ? this.loggedUser.default_phone.e164
      : null;
  },
  methods: {
    handleSubmit() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;
      api
        .sendSmsWithClientMobileApp({ phone: this.form.number })
        .then(() => {
          successNotification("sms_sent");
          this.closeDialog();
        })
        .catch((error) => {
          errorNotification("phone_is_invalid", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-mobile-app-link-dialog-sms-tab {
  padding: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .row {
    width: 100%;
  }
}
</style>
